.btn-primary {
  background-color: #ddd;
  border: 2px solid #ddd;
  border-radius: 0;
  cursor: pointer;
  color: #000;
}

.admin-login-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  form {
    display: inline-flex;

    input,
    button {
      padding: 8px 10px;
      font-size: 16px;
      box-sizing: border-box;
      margin-right: 15px;
    }
    button {
      margin-right: 0;
    }
  }
}
.admin-nav-box {
  background-color: #eee;
  border-bottom: 1px solid #ddd;
}
.admin-nav-container {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  .admin-nav-list {
    flex: 6;
    & > ul {
      display: flex;
      list-style: none;
      padding: 0;
      & > li {
        flex: 1;
        text-align: center;
      }
    }
  }
  .admin-nav-side {
    flex: 2;
    text-align: right;
  }
}

.admin-table {
  display: flex;
  flex-wrap: wrap;
}

.admin-table-item {
  width: 50%;
  display: block;
  padding: 20px 0;

  > h2 {
    display: block;
  }
  
  > div {
    max-height: 100px;
    overflow: hidden;
    padding: 0 20px;
  }
}

.admin-content {
  margin-bottom: 100px;
  position: relative;

  .form-title {
    margin-top: 50px;
  }

  .btn-delete {
    position: absolute;
    bottom: 0;
    border: 1px solid #e40b0b;
    color: #e40b0b;
    transition: all 0.25s ease;

    &:hover {
      background: #e40b0b;
      color: #fff;
    }
  }

  .input-item {
    margin-bottom: 15px;

    .input-label {
      margin-bottom: 5px;

      + input,
      + textarea {
        width: 100%;
        padding: 4px 10px;
        box-sizing: border-box;
        border: 1px solid #333;
      }
    }
  }
}

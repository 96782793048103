.home {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.home-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../img/bg-center.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-bg-animate {
  transition: all 0.5s ease;
}

.home-box {
  overflow: hidden;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  display: flex;
  margin-top: 6px;
}

.block {
  flex: 1;
  align-self: flex-end;
  margin-bottom: 58px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 52px;
  }
  &:nth-child(2) {
    text-align: left;
  }
  &:nth-child(4) {
    text-align: right;
  }
}

.block a {
  text-decoration: none;
  color: #afaeae;
}

.block--main {
  flex: 6;
  margin-top: 0;
  margin-bottom: 0;
}

.block--main p {
  line-height: 1;
  font-size: 0.75rem;
  letter-spacing: 6px;
  margin: 30px 0 0 0;
}

.block--main h1 {
  margin: 0;
  & > span {
    display: none;
  }
  & > svg {
    fill: #fff;
    width: 142px;
    height: 64px;
    @media (max-width: 768px) {
      width: 85px;
      height: 38px;
      margin-top: 10px;
    }
  }
}

.block--main h1 + p {
  @media (max-width: 768px) {
    letter-spacing: 1px;
  }
}

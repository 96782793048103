.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 6px;
}

.swiper-box {
  width: 100%;
  max-width: 1188px;
  height: 100%;
  position: absolute;
  padding: 180px 80px 0 114px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding-left: 129px;
    padding-right: 95px;
  }
}

.swiper-slide {
  overflow: hidden;
  max-width: 100%;
  iframe,
  video {
    width: 100%;
    height: 100%;
  }
}

.swiper-container {
  height: 100%;
  width: 100%;
}

.swiper-desc-outer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-desc {
  width: 100%;
  max-height: 100%;
  background: #fff;
  max-width: 588px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swiper-desc::-webkit-scrollbar {
  width: 2px;
}

.swiper-desc::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 100px;
}

.swiper-desc::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border-radius: 100px;
}

.swiper-desc-text {
  padding-left: 15px;
  padding-right: 15px;
}

.swiper-desc-img {
  position: relative;

  img {
    width: 100%;
  }
}

.swiper-desc-attr {
  margin-top: 10px;
  list-style: none;
  padding: 0;
  font-size: 0.8125rem;
  white-space: pre-wrap;
  line-height: 1.8;
  li {
    display: inline;
  }
}

.swiper-desc-detail {
  font-size: 0.8125rem;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.8;
  white-space: pre-wrap;
}

.swiper-desc-title {
  font-size: 1rem;
  margin-bottom: 20px;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-attr {
  position: fixed;
  right: 0;
  width: 100%;
  text-align: right;
  bottom: 40px;
  @media (max-width: 1200px) {
    right: 15px;
  }
}

.swiper-attr p span {
  display: inline-block;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}

.swiper-attr p .swiper-attr-value:after {
  content: "‧";
  padding: 0 0.25rem;
}

.swiper-attr p .swiper-attr-value:nth-child(4):after {
  display: none;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -80px;
  z-index: 14;
}

.img-warp {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-pagination-v {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 1200px) {
    right: 15px;
  }
}

.swiper-pagination-v > span {
  margin: 10px 0;
  display: block;
}

.swiper-pagination-h {
}

.swiper-container-v > .swiper-wrapper > .swiper-slide {
  padding-bottom: 140px;
  box-sizing: border-box;
}

.swiper-container-v > .swiper-wrapper > .swiper-slide .swiper-container {
  overflow: visible;
}

.swiper-pagination-bullet-active {
  background: #000;
  opacity: 0.5;
}

/* gif player */

.gif-fade,
.gif-simple {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.gif-simple img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
}

.gif-simple img.active {
  opacity: 1;
  position: static;
}

.gif-fade img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  opacity: 0;
  transition: 3s opacity cubic-bezier(0.6, -0.28, 0.74, 0.05);
}

.gif-fade img.active {
  opacity: 1;
}

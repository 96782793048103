.show {
  z-index: 999;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.show .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}
.show .img-show {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.66);
}
.img-show .close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
  font-size: 40px;
  color: #ebeaea;
}

.img-show picture {
  display: flex;
  align-items: center;
  min-height: 100vh;

  justify-content: center;

  img {
    max-width: 100%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .img-show picture {
    height: 100vh;
    align-items: flex-start;
  }
}

@import "../lib/swiper/swiper.min.css";
/* @import "../lib/custom-scroll-bar/jquery.mCustomScrollbar.min.css"; */
@import "_base.css";
@import "_layout.css";
@import "_silder.css";
@import "_lan.css";
@import "_home.css";
@import "_page.css";
@import "_page.project.css";
@import "_project.css";
@import "_admin.css";
@import "_img-popup.css";

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*No image is displayed during printing*/
@media print {
  img,
  svg {
    display: none;
  }
}

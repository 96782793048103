.lang-switch {
  position: absolute;
  z-index: 99;
  top: 55px;
  right: 0;
  @media (max-width: 768px) {
    top: 24px;
    right: 64px;
    line-height: 1;
  }
}

.lang-switch-box {
  position: relative;
  z-index: 99;
}

.lang-switch a {
  text-decoration: none;
}

.lang-switch a > span {
  display: inline-block;
}

.lang-switch a.true > span,
.lang-switch a:hover > span {
  text-decoration: underline;
}

.lang-switch a:after {
  content: "";
  padding: 0 0.35rem;

  @media (max-width: 768px) {
    display: none;
  }
}

.lang-switch a:last-child:after {
  display: none;
}

@media (max-width: 768px) {
  .lang-switch a.true {
    display: none;
  }
}

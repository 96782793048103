html,
body {
  font-size: 14px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Lucida Grande",
    Helvetica, Arial, "Microsoft YaHei", FreeSans, Arimo, "Droid Sans",
    "wenquanyi micro hei", "Hiragino Sans GB", "Hiragino Sans GB W3", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  @media (max-width: 768px) {
    font-size: 81.25%;
  }
}

.xup-container {
  max-width: 1188px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* header */

.header {
  padding: 55px 0;
  height: 55px;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 32px;
  }
}

.header-menu {
  list-style: none;
  background: #fff;
  margin: 0;
  overflow: hidden;
  text-transform: capitalize;
  @media (max-width: 768px) {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    font-size: 14px;
    opacity: 0;
    max-height: 0;
    transition:
      max-height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      opacity 0.5s
        linear(
          0 0%,
          0 1.8%,
          0.01 3.6%,
          0.03 6.35%,
          0.07 9.1%,
          0.13 11.4%,
          0.19 13.4%,
          0.27 15%,
          0.34 16.1%,
          0.54 18.35%,
          0.66 20.6%,
          0.72 22.4%,
          0.77 24.6%,
          0.81 27.3%,
          0.85 30.4%,
          0.88 35.1%,
          0.92 40.6%,
          0.94 47.2%,
          0.96 55%,
          0.98 64%,
          0.99 74.4%,
          1 86.4%,
          1 100%
        );
  }
}

@media (max-width: 768px) {
  .header-menu::before,
  .header-menu::after {
    content: "";
    display: block;
    clear: both;
    height: 1px;
    background-color: #eee;
    margin: 16px 0;
  }
}

.header-menu-sub {
  display: none;

  @media (max-width: 768px) {
    display: block;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    display: inline-block;
    @media (max-width: 768px) {
      flex: 0 0 100%;
    }
    > a {
      text-decoration: none;
      padding: 8px 10px;
      display: block;
    }
  }
}

.header-menu > li {
  float: left;
  display: block;

  @media (max-width: 768px) {
    float: none;
    margin-top: 24px;
    padding: 0 5px;
  }
}

.header-menu > li:first-child {
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.header-menu > li > a {
  text-decoration: none;
  padding: 0 10px;
  display: block;
  @media (max-width: 768px) {
    font-weight: bold;
    padding: 0;
  }
}

.header-menu > .header-menu-item.hover,
.header-menu > .header-menu-item:hover {
  .header-menu-sub {
    display: block;
    padding-left: 0;
    list-style: none;
    position: absolute;
  }
}

.header-menu > .header-menu-item .header-menu-sub {
  @media (max-width: 768px) {
    margin-top: 10px;
    position: static !important;
    display: flex !important;
    flex-wrap: wrap;
  }
}

.header-logo {
  text-decoration: none;
  width: 115px;
  height: 54px;
  display: block;
  float: left;
  overflow: hidden;
  @media (max-width: 768px) {
    float: none;
    position: relative;
    z-index: 2;
    height: 28px;
  }
}

.header-logo h1 {
  position: relative;
  margin: 0;
  z-index: 2;
  svg {
    fill: rgb(95, 95, 95);
    position: absolute;
    left: 0;
    top: 0;
    height: 49px;
    @media (max-width: 768px) {
      height: 28px;
    }
  }
}

/* page */

.article {
  display: flex;
  padding-left: 164px;
  padding-right: 80px;
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.article-title {
  font-size: 1.125rem;
  padding-left: 164px;
  letter-spacing: 2px;
  margin: 0;
  text-transform: capitalize;
  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.article--full {
  display: block;
  padding-left: 164px;
  padding-right: 80px;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.article--img {
  display: block;
  padding-left: 164px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.article-img {
  padding-left: 40px;
  flex: 10;
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  font-size: 11px;
  @media (max-width: 768px) {
    padding-left: 0;
    justify-content: center;
  }
  img {
    max-width: 100%;
  }
}

.article-text {
  flex: 14;
  padding-right: 40px;
  @media (max-width: 768px) {
    padding-right: 0;
  }
  .highlight {
    margin-top: 0.875rem;
    font-size: 1.125rem;
    font-weight: 600;
  }
  p {
    line-height: 1.8;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.header-menu-toggle {
  position: absolute;
  right: 15px;
  top: 16px;
  background: none;
  border: 0;
  color: var(--text-color);
  padding: 6px 0;
}

.opacity-1 {
  opacity: 1 !important;
}
.h-100vh {
  max-height: 100vh !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.py-12 {
  padding: 48px 0 !important;
}

.relative {
  position: relative !important;
}

.article-img-philosophy {
  max-width: 210px;

  @media (max-width: 768px) {
    display: none;
  }

  > p {
    font-size: 0.625rem;
    margin: 0;
    letter-spacing: 1px;
  }
}

.article-img-philosophy-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;

    img {
      width: 100%;
    }
  }
}

.line-spacing {
  height: 1px;
  background-color: #eee;
  margin: 54px auto;
  margin-left: 164px;
  margin-right: 80px;
}

@media (max-width: 768px) {
  .line-spacing {
    margin-left: auto;
    margin-right: auto;
  }
}

.header-menu--project {
  .header-menu-sub {
    display: block;
    padding-left: 0;
  }
}

.project-container {
  padding-left: 85px;
  padding-right: 85px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.project-row {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  margin: -4px;
}

.project-col {
  padding: 8px;
  box-sizing: border-box;
  width: 16.666666%;
  max-width: 16.666666%;
  flex: 0 0 16.666666%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  &-archive {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  @media (max-width: 768px) {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
    order: 2;

    padding-left: 4px;
    padding-right: 4px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  img {
    width: 100%;
    aspect-ratio: 200/143;
  }

  a {
    @media (max-width: 768px) {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
}

.greyly {
  .project-col {
    filter: grayscale(100%);
    filter: gray;
    opacity: 0.25;

    @media (max-width: 768px) {
      filter: none;
      opacity: 1;
    }

    &:hover {
      filter: none;
      opacity: 1;
    }
  }
}

@define-mixin types $type {
  [$type] {
    [data-type] {
      filter: grayscale(100%) !important;
      filter: gray !important;
      opacity: 0.25 !important;
    }
    [data-type*="$type"] {
      filter: none !important;
      opacity: 1 !important;
    }
  }
  [data-catalog="$type"] {
    [data-type*="$type"] {
      filter: none;
      opacity: 1;
      @media (max-width: 768px) {
        order: 1;
      }
    }
  }
}

@mixin types 景观设计 {
}

@mixin types 建筑设计 {
}

@mixin types 室内设计 {
}

@mixin types 城市设计 {
}

.project-col.project-col-archive {
  a {
    font-size: 14px;
    text-decoration: none;
  }
}

/* color */
:root {
  --text-color: rgb(95, 95, 95);

  --nav-text-size: 14px;
  --lan-switch-text-size: 14px;
  --slider-desc-text-size: 12px;
  --page-heading-size: 16px;
  --img-box-width: 960px;
  --container-width: 1110px;
}

/* a link */
a {
  color: var(--text-color);
}

.text-right {
  text-align: right;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

img {
  max-width: 100%;
}

[data-desktop] {
  @media (max-width: 768px) {
    display: none;
  }
}

[data-mobile] {
  @media (min-width: 769px) {
    display: none;
  }
}

.slider-box {
  height: 100%;
  @media (max-width: 768px) {
    height: auto;
    margin: 0 -15px;
  }
  .swiper-pagination-bullets {
    bottom: 16px;
  }
  .swiper-attr {
    position: absolute;
  }
}

.swiper-project-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide-placeholder {
  height: 0;
  padding-bottom: 75%;
}

.slider-container {
  position: relative;
  padding-left: 114px;
  padding-right: 114px;
  height: 100%;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.slider-other-container {
  position: relative;
  overflow: hidden;
  text-align: right;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    display: flex;
  }
}

.slider-attr {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 100%;
  text-align: right;
  bottom: 0;
}

.swiper-pagination-box {
  position: absolute;
  top: 0;
  height: 50px;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    position: relative;
    height: auto;
    .swiper-pagination {
      position: static;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
  .swiper-pagination-bullet {
    margin: 0 6px;
  }
}

.slider-footer {
  position: fixed;
  width: 100%;
  bottom: 5%;
  left: 0;
  z-index: 1;

  .swiper-pagination {
    position: static !important;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    position: static;
  }
}

.slider-main {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-bottom: 10%;
  padding-top: 10%;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  @media (max-width: 768px) {
    position: static;
    padding: 0;
  }
}

.swiper-button-black {
  transform: scale(0.5);
  @media (max-width: 768px) {
    display: none;
  }
  &.swiper-button-prev {
    left: 0;
    transform-origin: left;
  }
  &.swiper-button-next {
    right: 0;
    transform-origin: right;
  }
}

.slider-attr-back {
  float: left;
  line-height: 50px;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    float: none;
  }
  a {
    text-decoration: none;
    text-transform: capitalize;
  }
}

.slider-attr-info {
  float: right;
  line-height: 50px;
  @media (max-width: 768px) {
    float: none;
  }
}

.swiper-slide-placeholder {
  @media (max-width: 768px) {
    display: none;
  }
}
.swiper-project-content {
  @media (max-width: 768px) {
    position: static;
    min-height: 200px;
  }
}

.swiper-wrapper {
  @media (max-width: 768px) {
    display: block;
    transform: none !important;
  }
}

.swiper-slide {
  @media (max-width: 768px) {
    margin: 32px 0;

    &:last-child {
      .swiper-project-content {
        position: relative;
      }
    }
  }
}
